import Amplify from "aws-amplify";

const AmplifyMiddleware = {
  init() {
    //Initialize Amplify
    Amplify.configure({
      Auth: {
        region: process.env.VUE_APP_COGNITO_REGION,
        userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
        userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
        mandatorySignIn: true,
        authenticationFlowType: "REFRESH_TOKEN_AUTH",
        oauth: {
          domain: process.env.VUE_APP_COGNITO_APP_DOMAIN,
          scope: [],
          redirectSignIn: process.env.VUE_APP_COGNITO_REDIRECT_URI,
          redirectSignOut: process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT,
          responseType: "code"
        }
      }
    });
  }
};

export default AmplifyMiddleware;
