import Vue from "vue";
import Vuex from "vuex";

// Plugins
import auth from "./common/auth.module";
import htmlClass from "./common/htmlclass.module";
import config from "./common/config.module";
import breadcrumbs from "./common/breadcrumbs.module";
import page from "./common/page.module";

// Modules
import accountSyncedHistory from "./modules/accountSyncedHistory.module";
import contactSyncedHistory from "./modules/contactSyncedHistory.module";

import layoutPlugin from "./common/plugins/layout.plugin";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [layoutPlugin],
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    page,
    accountSyncedHistory,
    contactSyncedHistory
  }
});
