const AUTH_MODULE = {
  TECHNICAL_DASHBOARD: ["role-sg-sala-query_newrelic_nrql"],
  BE_CRITICALS: [
    "role-sg-sala-query_opsgenie_alerts",
    "role-sg-sala-query_new_relic_incidents",
    "role-sg-sala-query_servicenow_accounts",
    "role-sg-sala-query_servicenow_configuration_items",
    "role-sg-sala-mutation_opsgenie_workflow_alert_ack",
    "role-sg-sala-query_new_relic_nrql"
  ],
  BE_AVAILABILITY_REPORTS: [
    "role-sg-sala-query_newrelic_availability",
    "role-sg-sala-mutation_newrelic_availability"
  ],
  BE_SERVICE_MANAGEMENT_REPORTS: [
    "role-sg-sala-query_servicenow_cases",
    "role-sg-sala-query_servicenow_changes",
    "role-sg-sala-query_servicenow_incidents",
    "role-sg-sala-query_servicenow_security_incidents",
    "role-sg-sala-query_servicenow_configuration_items",
    "role-sg-sala-query_servicenow_ratings",
    "role-sg-sala-query_servicenow_service_requests",
    "role-sg-sala-query_servicemanagement_reports",
    "role-sg-sala-query_dynamics365_accounts"
  ],
  GROUP_MY_INSIGHTS: ["role-sg-connect-query_power_bi_reports"],
  GROUP_NRQL: ["role-sg-sala-query_newrelic_nrql"],
  GROUP_SMS: ["role-sg-sala-mutation_aws_sms"],
  GROUP_FINANCE_PRICING_UPLOAD: ["role-sg-sala-query_frontend_pre_signed_urls"],
  GROUP_SERVICE_MANAGEMENT_REPORTS: [
    "role-sg-sala-query_servicemanagement_reports"
  ],
  GROUP_MSC: ["role-sg-sala-query_msc", "role-sg-sala-mutation_msc"],
  GROUP_SALES_SHAREPOINT_XLSB_UPLOAD: [
    "role-sg-sala-mutation_dynamics365_quotes",
    "role-sg-sala-mutation_dynamics365_product_matrix"
  ],
  GROUP_SALES_DYNAMICS_CSV_UPLOAD: ["role-sg-sala-mutation_dynamics365_quotes"],
  IO_ACCOUNTS: [
    "role-sg-sala-query_dynamics365_accounts",
    "role-sg-sala-query_servicenow_accounts",
    "role-sg-sala-mutation_workflow_init_sync_account"
  ],
  IO_CONTACTS: [
    "role-sg-sala-query_dynamics365_contacts",
    "role-sg-sala-query_dynamics365_contacts",
    "role-sg-sala-query_okta_users",
    "role-sg-sala-mutation_workflow_init_sync_contact"
  ],
  MS_ACCOUNTS: [
    "role-sg-sala-query_dynamics365_accounts",
    "role-sg-sala-mutation_frontend_my_feature_plans"
  ],
  MS_FEATURES: [
    "role-sg-sala-query_frontend_my_feature_plans",
    "role-sg-sala-mutation_frontend_my_feature_plans"
  ]
};

export { AUTH_MODULE };
