const NotificationService = {
  success(message, options) {
    var snackbarOptions = options ?? {};
    snackbarOptions.color = "green";
    window.app.$root.$snackbar.showSnackbar(message, snackbarOptions);
  },
  dataNotFound(message, options) {
    var snackbarOptions = options ?? {};
    snackbarOptions.color = "blue";
    window.app.$root.$snackbar.showSnackbar(
      message ?? "No data found!",
      snackbarOptions
    );
  },
  error(message, options) {
    var snackbarOptions = options ?? {};
    snackbarOptions.color = "red";
    window.app.$root.$snackbar.showSnackbar(
      message ?? "No data found!",
      snackbarOptions
    );
  }
};

export default NotificationService;
