import { RbacService } from "@/core/services/rbac.service";
import { AUTH_MODULE } from "@/core/config/role.config";

export default function rbac(Vue) {
  Vue.mixin({
    computed: {
      $roles() {
        return AUTH_MODULE;
      }
    },
    methods: {
      /**
       * Validate whether a user can read the page
       * @param roles {Array<String>}
       * @returns {boolean}
       */
      $can(roles) {
        return RbacService.isAllowed(roles);
      }
    }
  });
}
