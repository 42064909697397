export default function abbreviatedString(input) {
  if (input) {
    var segments = input.split("@");
    if (segments.length == 2) {
      var nameSegments = segments[0].split(".");
      if (nameSegments.length > 1) {
        return (
          nameSegments[0]
            .split(" ")
            .map(n => n[0])
            .join("")
            .toUpperCase() +
          nameSegments[1]
            .split(" ")
            .map(n => n[0])
            .join("")
            .toUpperCase()
        );
      }
    }
  } else {
    return "";
  }
}
