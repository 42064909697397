const bgroutes = {
  name: "BG",
  path: "bg",
  component: () => import("./BG.vue"),
  children: [
    {
      path: "navigation-menu",
      name: "Navigation Menu BG",
      component: () =>
        import("@/view/pages/bg/navigationmenu/NavigationMenu.vue")
    }
  ]
};

export { bgroutes };
