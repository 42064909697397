import { AUTH_MODULE } from "@/core/config/role.config";

const dashboardRoutes = {
  path: "/dashboard",
  name: "dashboard",
  component: () => import("@/view/pages/dashboard/Dashboard.vue")
};

const technicalDashboardRoutes = {
  path: "/technical-dashboard",
  name: "technical-dashboard",
  component: () => import("@/view/pages/dashboard/TechnicalDashboard.vue"),
  meta: {
    requiresAuth: true,
    roles: AUTH_MODULE.TECHNICAL_DASHBOARD
  }
};

export { dashboardRoutes, technicalDashboardRoutes };
