import { AUTH_MODULE } from "../../../core/config/role.config";

const integration_office_routes = {
  name: "IntegrationOffice",
  path: "integration-office",
  component: () => import("./IntegrationOffice.vue"),
  children: [
    {
      path: "navigation-menu",
      name: "Navigation Menu Integration Office",
      component: () =>
        import(
          "@/view/pages/integration_office/navigationmenu/NavigationMenu.vue"
        ),
      meta: {
        requiresAuth: true,
        roles: [...AUTH_MODULE.IO_ACCOUNTS, ...AUTH_MODULE.IO_CONTACTS]
      }
    },
    {
      path: "command-base-account",
      name: "Command Base Account",
      component: () =>
        import(
          "@/view/pages/integration_office/command-base-account/CommandBaseAccount.vue"
        ),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.IO_ACCOUNTS
      }
    },
    {
      path: "command-base-contact",
      name: "Command Base Contact",
      component: () =>
        import(
          "@/view/pages/integration_office/command-base-contact/CommandBaseContact.vue"
        ),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.IO_CONTACTS
      }
    }
  ]
};

export { integration_office_routes };
