import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import { RESET_LAYOUT_CONFIG } from "@/store/common/config.module";

// Custom routes
import { login_routes, logout_routes } from "./view/pages/auth/_routes";
import profileroutes from "@/view/pages/profile/_routes";
import {
  dashboardRoutes,
  technicalDashboardRoutes
} from "@/view/pages/dashboard/_routes";
import { beroutes, criticalsscreenroutes } from "@/view/pages/be/_routes";
import { nlroutes } from "@/view/pages/nl/_routes";
import { grouproutes } from "@/view/pages/group/_routes";
import { dkroutes } from "@/view/pages/dk/_routes";
import { bgroutes } from "@/view/pages/bg/_routes";
import { integration_office_routes } from "@/view/pages/integration_office/_routes";
import { RbacService } from "@/core/services/rbac.service";
import dayjs from "dayjs";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        dashboardRoutes,
        technicalDashboardRoutes,
        profileroutes,
        beroutes,
        nlroutes,
        grouproutes,
        dkroutes,
        bgroutes,
        integration_office_routes
      ]
    },
    criticalsscreenroutes,
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth.vue"),
      children: [login_routes, logout_routes],
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/implicit/callback",
      component: () => import("@/view/pages/auth/callback/Callback.vue")
    },
    {
      path: "*",
      name: "404",
      component: () => import("@/view/pages/error/Error.vue")
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  if (!store.getters.currentUser) await store.dispatch("fetchUser");
  let validSeconds = store.getters.accessTokenPayload?.exp - dayjs().unix();
  if (validSeconds <= 300) await store.dispatch("refreshSession");
  await store.dispatch(RESET_LAYOUT_CONFIG);

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  const canNavigate = to.matched.some(route => {
    const allowedRoles = route.meta?.roles;
    if (allowedRoles) {
      return RbacService.isAllowed(allowedRoles);
    }
  });

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      if (canNavigate) {
        next();
      } else {
        if (from.name === "404") {
          next("/");
        } else {
          next("/404");
        }
      }
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
