(function () {
  let LINQ = {
    utils: {
      equalityComparer(a, b) {
        if (!a || !b) return false;
        return a === b || a.valueOf() === b.valueOf();
      },
      sortComparer(a, b) {
        if (a === b) return 0;
        if (a === null) return -1;
        if (b === null) return 1;
        if (typeof a === "string")
          return a.toString().localeCompare(b.toString());
        return a.valueOf() - b.valueOf();
      },
      predicate() {
        return true;
      },
      selector(t) {
        return t;
      }
    },
    methods: {
      /**
       * Applies an accumulator function over a sequence
       * E.g.: [2, 4, 6].aggregate((a, b) => { return a + b; }, 2) => 14
       * @param {function(predicate)} [propertySelector]
       * @returns {boolean}
       */
      aggregate(func, seed) {
        let arr = this.slice(0);
        let l = this.length;
        if (seed === null) seed = arr.shift();

        for (let i = 0; i < l; i++) seed = func(seed, arr[i], i, this);

        return seed;
      },
      /**
       * Determines whether all elements of a sequence satisfy a condition.
       * E.g.: [1, 2, 3].all(x => x > 0) => true
       * @param {function(predicate)} [propertySelector]
       * @returns {boolean}
       */
      all(predicate, context) {
        context = context || window;
        predicate = predicate || LINQ.utils.predicate;
        let f =
          this.every ||
          function (p, c) {
            return this.length === this.where(p, c).length;
          };
        return f.apply(this, [predicate, context]);
      },
      /**
       * Determines whether any element of a sequence exists or satisfies a condition.
       * E.g.: [1, 2, 3].any(x => x > 2) => true
       * @param {function(predicate)} [propertySelector]
       * @returns {boolean}
       */
      any(predicate, context) {
        context = context || window;
        predicate = predicate || LINQ.utils.predicate;
        let f =
          this.some ||
          function (p, c) {
            let l = this.length;
            if (!p) return l > 0;
            while (l-- > 0)
              if (p.call(c, this[l], l, this) === true) return true;
            return false;
          };
        return f.apply(this, [predicate, context]);
      },
      /**
       * Computes the average of a sequence of numeric values.
       * E.g.: [2, 4, 6].average() => 4
       * E.g.: [{quantity: 6}, {quantity: 4}].average(x => x.quantity) => 5
       * @param {function(predicate)} [propertySelector]
       * @returns {number}
       */
      average(predicate = obj => obj) {
        const intialValue = 0;
        return (
          this.reduce((sum, obj) => sum + predicate(obj), intialValue) /
          this.length
        );
      },
      /**
       * Returns the number of elements in a sequence.
       * E.g.: [2, 4, 6].count() => 3
       * E.g.: [{quantity: 6}, {quantity: 4}].count(x => x.quantity > 4) => 1
       * @param {function(predicate)} [propertySelector]
       * @returns {number}
       */
      count(predicate = obj => obj) {
        return this.filter(predicate).length;
      },
      /**
       * Returns distinct elements from a sequence.
       * E.g.: [1, 2, 3, 2, 3].distinct() => [1, 2, 3]
       * TODO: Using ES6 if performance faster
       * var duplicatedArray = [1, 2, 3, 4, 5, 1, 1, 1, 2, 3, 4];
       * var uniqueArray = Array.from(new Set(duplicatedArray));
       * @returns {[]}
       */
      distinct() {
        let arr = [];
        let l = this.length;
        for (let i = 0; i < l; i++) {
          if (!arr.includes(this[i])) arr.push(this[i]);
        }
        return arr;
      },
      /**
       * Produces the set difference of two sequences.
       * E.g.: [1, 2, 3].except([3, 4, 5]) => [1, 2]
       * @param {Array} other
       * @returns {[]}
       */
      except(other, idSelector = obj => obj) {
        const otherSet = new Set([...other.map(idSelector)]);
        // Reference: http://2ality.com/2015/01/es6-set-operations.html
        const difference = new Set(
          this.filter(object => !otherSet.has(idSelector(object)))
        );
        return [...difference];
      },
      /**
       * Returns the first element of a sequence.
       * E.g.: [1, 2, 3].first() => 1
       * E.g.: [1, 2, 3].first(x => x > 1) => 2
       * @param {function(predicate)} [propertySelector]
       * @returns {any}
       */
      first(predicate = obj => obj) {
        return this.filter(predicate)[0];
      },
      /**
       * Groups the elements of a sequence.
       * E.g.: [{category: "Phone"}, {category: "Phone"}, {category: "Laptop"}].groupBy(x => x.category)
       * E.g.: => [[{category: "Phone"}, {category: "Phone"}], [{category: "Laptop"}]]
       * @param {function(predicate)} [propertySelector]
       * @returns {[]}
       */
      groupBy(selector, comparer) {
        let grp = [];
        let l = this.length;
        comparer = comparer || LINQ.utils.equalityComparer;
        selector = selector || LINQ.utils.selector;

        for (let i = 0; i < l; i++) {
          let k = selector(this[i]);
          let g = grp.first(function (u) {
            return comparer(u.key, k);
          });

          if (!g) {
            g = [];
            g.key = k;
            grp.push(g);
          }

          g.push(this[i]);
        }
        return grp;
      },
      /**
       * Produces the set intersection of two sequences.
       * E.g.: [1, 2, 3].intersect([3, 4, 5]) => [3]
       * @param {Array} other
       * @returns {[]}
       */
      intersect(other, idSelector = obj => obj) {
        const otherSet = new Set([...other.map(idSelector)]);
        // Reference: http://2ality.com/2015/01/es6-set-operations.html
        const intersection = new Set(
          this.filter(object => otherSet.has(idSelector(object)))
        );
        return [...intersection];
      },
      /**
       * Returns the last element of a sequence.
       * E.g.: [1, 2, 3].last() => 3
       * E.g.: [1, 2, 3].last(x => x > 1) => 3
       * @param {function(predicate)} [propertySelector]
       * @returns {any}
       */
      last(predicate = obj => obj) {
        const filtered = this.filter(predicate);
        return filtered[filtered.length - 1];
      },
      /**
       * Returns the maximum value in a sequence of values.
       * E.g.: [2, 4, 6].max() => 6
       * E.g.: [{quantity: 6}, {quantity: 4}].max(x => x.quantity) => 6
       * @param {function(predicate)} [propertySelector]
       * @returns {number}
       */
      max(s) {
        s = s || LINQ.utils.selector;
        let l = this.length;
        let max = s(this[0]);
        while (l-- > 0) if (s(this[l]) > max) max = s(this[l]);
        return max;
      },
      /**
       * Returns the minimum value in a sequence of values.
       * E.g.: [2, 4, 6].max() => 2
       * E.g.: [{quantity: 6}, {quantity: 4}].max(x => x.quantity) => 4
       * @param {function(predicate)} [propertySelector]
       * @returns {number}
       */
      min(s) {
        s = s || LINQ.utils.selector;
        let l = this.length;
        let min = s(this[0]);
        while (l-- > 0) if (s(this[l]) < min) min = s(this[l]);
        return min;
      },
      /**
       * Sorts the elements of a sequence in ascending order.
       * E.g.: [3, 2, 6].orderBy(x => x) => [2, 3, 6]
       * @param {function(predicate)} [propertySelector]
       * @returns {[]}
       */
      orderBy(selector, comparer) {
        comparer = comparer || LINQ.utils.sortComparer;
        let arr = this.slice(0);
        let fn = function (a, b) {
          return comparer(selector(a), selector(b));
        };

        arr.thenBy = function (selector, comparer) {
          comparer = comparer || LINQ.utils.sortComparer;
          return arr.orderBy(LINQ.utils.selector, function (a, b) {
            let res = fn(a, b);
            return res === 0 ? comparer(selector(a), selector(b)) : res;
          });
        };

        arr.thenByDescending = function (selector, comparer) {
          comparer = comparer || LINQ.utils.sortComparer;
          return arr.orderBy(LINQ.utils.selector, function (a, b) {
            let res = fn(a, b);
            return res === 0 ? -comparer(selector(a), selector(b)) : res;
          });
        };

        return arr.sort(fn);
      },
      /**
       * Sorts the elements of a sequence in descending order.
       * E.g.: [3, 2, 6].orderByDescending(x => x) => [6, 3, 2]
       * @param {function(predicate)} [propertySelector]
       * @returns {[]}
       */
      orderByDescending(selector, comparer) {
        comparer = comparer || LINQ.utils.sortComparer;
        return this.orderBy(selector || LINQ.utils.selector, function (a, b) {
          return -comparer(a, b);
        });
      },
      /**
       * Remove an item from the list
       * E.g.: [3, 2, 6].remove(2) => [3, 6]
       * @param {any} item
       */
      remove(item) {
        let i = this.indexOf(item);
        if (i !== -1) this.splice(i, 1);
      },
      /**
       * Remove all items from the list that match condition
       * E.g.: [3, 2, 6].removeAll(x => x > 2) => [2]
       * @param {function(predicate)} [propertySelector]
       */
      removeAll(predicate) {
        let item;
        let i = 0;
        while ((item = this.first(predicate)) != null) {
          i++;
          this.remove(item);
        }

        return i;
      },
      /**
       * Take each element of a sequence to an Array and flattens the resulting sequences into one sequence.
       * E.g.: [{ name: "even", number: [2, 4, 6] }, name: "odd", { number: [1, 3, 5] }].selectMany(x => x.number) => [2, 4, 6, 1, 3, 5]
       * @param {function(predicate)} [propertySelector]
       * @param {function(predicate)} [resSelector]
       */
      selectMany(propertySelector, resSelector) {
        resSelector =
          resSelector ||
          function (i, res) {
            return res;
          };
        return this.aggregate(function (a, b) {
          return a.concat(
            propertySelector(b).select(function (res) {
              return resSelector(b, res);
            })
          );
        }, []);
      },
      /**
       * Bypasses a specified number of elements in a sequence and then returns the remaining elements.
       * E.g.: [1, 2, 3].skip(1) => [2, 3]
       * @param {number} count
       * @returns {[]}
       */
      skip(count) {
        return this.slice(count, this.length);
      },
      /**
       * Computes the sum of a sequence of numeric values.
       * E.g.: [1, 2, 3].sum() => 6
       * E.g.: [{quantity: 6}, {quantity: 5}].sum(x => x.quantity) => 11
       * @param {function(predicate)} [propertySelector]
       * @returns {number}
       */
      sum(propertySelector = obj => obj) {
        const intialValue = 0;
        return this.reduce(
          (sum, obj) => sum + propertySelector(obj),
          intialValue
        );
      },
      /**
       * Returns a specified number of contiguous elements from the start of a sequence.
       * E.g.: [1, 2, 3].take(2) => [1, 2]
       * @param {number} count
       * @returns {[]}
       */
      take(count) {
        return this.slice(0, count);
      },
      /**
       * Produces the set union of two sequences.
       * E.g.: [1, 2, 3].union([4, 5, 6]) => [1, 2, 3, 4, 5, 6]
       * @param {Array} other
       * @returns {*[]}
       */
      union(other) {
        return [...new Set([...this, ...other])];
      }
    }
  };

  Array.prototype.aggregate = LINQ.methods.aggregate;
  Array.prototype.all = LINQ.methods.all;
  Array.prototype.any = LINQ.methods.any;
  Array.prototype.average = LINQ.methods.average;
  Array.prototype.count = LINQ.methods.count;
  Array.prototype.distinct = LINQ.methods.distinct;
  Array.prototype.except = LINQ.methods.except;
  Array.prototype.first = LINQ.methods.first;
  Array.prototype.groupBy = LINQ.methods.groupBy;
  Array.prototype.intersect = LINQ.methods.intersect;
  Array.prototype.last = LINQ.methods.last;
  Array.prototype.max = LINQ.methods.max;
  Array.prototype.min = LINQ.methods.min;
  Array.prototype.orderBy = LINQ.methods.orderBy;
  Array.prototype.orderByDescending = LINQ.methods.orderByDescending;
  Array.prototype.remove = LINQ.methods.remove;
  Array.prototype.removeAll = LINQ.methods.removeAll;
  Array.prototype.select = Array.prototype.map;
  Array.prototype.selectMany = LINQ.methods.selectMany;
  Array.prototype.skip = LINQ.methods.skip;
  Array.prototype.sum = LINQ.methods.sum;
  Array.prototype.take = LINQ.methods.take;
  Array.prototype.union = LINQ.methods.union;
  Array.prototype.where = Array.prototype.filter;
})();
