import abbreviatedString from "./abbreviatedString.filter";
import formatDate from "./formatDate.filter";
import highlight from "./highlight.filter";

export default {
  install(Vue) {
    Vue.filter("abbreviatedString", abbreviatedString);
    Vue.filter("formatDate", formatDate);
    Vue.filter("highlight", highlight);
  }
};
