import dayjs from "dayjs";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

// action types
export const ADD_ACCOUNT_SYNCED_HISTORY = "addAccountSyncedHistory";
export const CLEAR_ACCOUNT_SYNCED_HISTORY = "clearAccountSyncedHistory";

// mutation types
export const SET_ACCOUNT_SYNCED_HISTORY = "setAccountSyncedHistory";

const state = {
  history: JSON.parse(localStorage.getItem("accountSyncedHistory")) || []
};

const getters = {
  getAccountSyncedHistory: state => state.history
};

const actions = {
  [ADD_ACCOUNT_SYNCED_HISTORY](state, payload) {
    state.state.history.push(payload);
    localStorage.setItem(
      "accountSyncedHistory",
      JSON.stringify(state.state.history)
    );
    state.commit(SET_ACCOUNT_SYNCED_HISTORY, state.state.history);
  },
  [CLEAR_ACCOUNT_SYNCED_HISTORY](state) {
    var history = [];
    state.state.history.forEach(item => {
      var dateCheck = dayjs().add(-14, "day");
      if (dayjs(item.created) > dateCheck) {
        history.push(item);
      }
    });

    state.state.history = history;
    localStorage.setItem(
      "accountSyncedHistory",
      JSON.stringify(state.state.history)
    );

    state.commit(SET_ACCOUNT_SYNCED_HISTORY, state.state.history);
  }
};

const mutations = {
  [SET_ACCOUNT_SYNCED_HISTORY](state, history) {
    state.history = history;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
