import { Auth } from "aws-amplify";
import NotificationService from "@/core/services/notification.service";

const state = {
  user: null
};

const getters = {
  accessToken: state =>
    state.user?.getSignInUserSession().getAccessToken().getJwtToken(),
  accessTokenPayload: state =>
    state.user?.getSignInUserSession().getAccessToken().decodePayload(),
  currentSession: state => state.user?.getSignInUserSession(),
  currentUser: state => state.user,
  idToken: state =>
    state.user?.getSignInUserSession().getIdToken().getJwtToken(),
  idTokenPayload: state =>
    state.user?.getSignInUserSession().getIdToken().decodePayload(),
  isAuthenticated: state =>
    state.user?.getSignInUserSession().isValid() | false,
  currentUserGroups: state =>
    state.user?.getSignInUserSession().getAccessToken().decodePayload()[
      "cognito:groups"
    ]
};

const actions = {
  async fetchUser({ commit }) {
    const user = await Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => commit("setAuthentication", null));
    commit("setAuthentication", user);
  },
  async refreshSession({ commit, getters }) {
    try {
      const refreshToken = getters.currentUser
        .getSignInUserSession()
        .getRefreshToken();
      getters.currentUser.refreshSession(refreshToken, async err => {
        if (err) {
          NotificationService.error("Refreshing session failed");
          commit("setAuthentication", null);
          location.reload();
        }
      });
    } catch {
      NotificationService.error("Refreshing session failed");
      commit("setAuthentication", null);
      location.reload();
    }
  },
  async signIn({ commit }) {
    let user = await Auth.currentAuthenticatedUser();
    if (user) {
      commit("setAuthentication", user);
      return true;
    } else {
      commit("setAuthentication", null);
      return false;
    }
  },
  async signOut({ commit }) {
    await Auth.signOut();
    commit("setAuthentication", null);
  }
};

const mutations = {
  setAuthentication(state, user) {
    state.user = user;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
