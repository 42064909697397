export default function highlight(words, keyword) {
  words = words || "";
  let replacedStr = (keyword || "")
    .trim()
    .split(/\s+/)
    .map(x => x.replace(/[-[\]{}()*+!<=:?.\\^$|#\s,]/g, "\\$&"))
    .join("|");

  return words.replace(
    new RegExp(replacedStr, "gi"),
    "<span class='bg-primary'>$&</span>"
  );
}
