const nlroutes = {
  name: "NL",
  path: "nl",
  component: () => import("./NL.vue"),
  children: [
    {
      path: "navigation-menu",
      name: "Navigation Menu NL",
      component: () =>
        import("@/view/pages/nl/navigationmenu/NavigationMenu.vue")
    }
  ]
};

export { nlroutes };
