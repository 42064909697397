<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{ zIndex: options.zIndex }"
  >
    <v-card
      class="card"
      :class="{ 'card-body-progress card-body-progress-overlay': viewLoading }"
    >
      <v-card-title class="py-4 text-dark">{{ title }}</v-card-title>
      <v-divider class="m-0"></v-divider>

      <v-card-text class="card-body font-size-base py-10" v-show="!!message">
        <div class="text-center card-body-progress">
          <v-progress-circular
            indeterminate
            color="primary"
            size="20"
            width="2"
          ></v-progress-circular>
        </div>
        <div v-html="message"></div>
      </v-card-text>

      <v-divider class="m-0"></v-divider>
      <v-card-actions class="px-6 py-3">
        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-1"
          text
          @click.native="cancel"
          :disabled="closeButtonDisabled"
          >{{ options.cancelButtonLabel }}</v-btn
        >
        <v-btn
          v-if="showAgreeButton"
          color="primary"
          text
          @click.native="agree"
          :disabled="viewLoading"
          >{{ options.agreeButtonLabel }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.card .card-body .card-body-progress {
  display: none;
}

.card.card-body-progress .card-body {
  position: relative;
  /* -webkit-animation: m-animate-fade-out .3s; */
  animation: m-animate-fade-out 0.3s;
}

.card.card-body-progress .card-body .card-body-progress {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
</style>
<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  data: () => ({
    viewLoading: false,
    closeButtonDisabled: false,
    showAgreeButton: true,
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      cancelButtonLabel: "Cancel",
      closeButtonDisabled: false,
      agreeButtonLabel: "Yes",
      color: "white",
      width: 440,
      zIndex: 200
    }
  }),
  methods: {
    open(title, message, options) {
      this.viewLoading = false;
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      this.showAgreeButton = true;
      this.closeButtonDisabled = this.options.closeButtonDisabled ?? false;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      if (!this.options.manualControl) {
        this.viewLoading = true;
        setTimeout(() => {
          this.resolve(true);
          this.dialog = false;
          this.viewLoading = false;
        }, 2500);
      } else {
        this.viewLoading = true;
        this.closeButtonDisabled = true;
        this.resolve(true);
      }
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
      this.viewLoading = false;
    },
    updateBodyContent(title, message, closeButtonDisabled = true) {
      this.title = title;
      this.message = message;
      this.options.cancelButtonLabel = "Close";
      this.showAgreeButton = false;
      this.viewLoading = false;
      this.closeButtonDisabled = closeButtonDisabled;
    }
  }
};
</script>
