// action types
export const PAGE_REQUEST_DATA = "requestData";

// mutation types
export const PAGE_SET_LOADING = "setPageloading";

export default {
  state: {
    loadingCount: 0
  },
  getters: {
    pageLoading(state) {
      return state.loadingCount > 0;
    }
  },
  actions: {
    [PAGE_REQUEST_DATA](state, payload) {
      state.commit(PAGE_SET_LOADING, payload);
    }
  },
  mutations: {
    [PAGE_SET_LOADING](state, loading) {
      if (state.loadingCount < 0) {
        state.loadingCount = 0;
      }

      if (loading) {
        state.loadingCount = state.loadingCount + 1;
      } else {
        state.loadingCount = state.loadingCount - 1;
      }
    }
  }
};
