import { PAGE_SET_LOADING } from "@/store/common/page.module";
import DialogService from "@/core/services/dialog.service";

const plugin = store => {
  store.subscribe(mutation => {
    switch (mutation.type) {
      case PAGE_SET_LOADING:
        if (
          store.state.page.loadingCount > 0 &&
          !DialogService.isProgressBarVisible()
        ) {
          DialogService.show();
        }

        if (
          store.state.page.loadingCount === 0 &&
          DialogService.isProgressBarVisible()
        ) {
          DialogService.hide();
        }
        break;
    }
  });
};

export default plugin;
