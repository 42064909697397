import store from "@/store";

export const RbacService = {
  /**
   * Validate whether a user can read the page
   * @param roles {Array<String>}
   * @returns {boolean}
   */
  isAllowed(roles) {
    const currentUserRoles = store.getters.currentUserGroups;
    let isAllowed = false;
    roles.forEach(role => {
      if (currentUserRoles?.includes(role)) {
        isAllowed = true;
      }
    });
    return isAllowed;
  }
};
