<template>
  <div>
    <!-- Using hide-overlay below allows for clicking while progress showing -->
    <v-dialog
      v-model="dialog"
      persistent
      :width="options.width"
      v-bind:style="{ zIndex: options.zIndex }"
    >
      <v-card :color="options.color" dark>
        <v-card-text>
          {{ message }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      class="font-size-base text-dark"
      v-model="snackbarVisible"
      :left="snackbar.x === 'left'"
      :right="snackbar.x === 'right'"
      :top="snackbar.y === 'top'"
      :bottom="snackbar.y === 'bottom'"
      color="white"
      :multi-line="snackbar.mode === 'multi-line'"
      :timeout="snackbar.timeout"
      :vertical="snackbar.mode === 'vertical'"
    >
      <div class="d-flex align-items-center">
        <v-icon class="mr-2" :color="snackbar.color">{{
          snackbar.icon
        }}</v-icon>
        <span
          class="text-wrap mr-auto text-dark font-size-base"
          style="word-break: break-word;"
        >
          {{ snackbar.message }}
        </span>
        <v-btn
          text
          color="black"
          @click="hideSnackbar"
          v-if="snackbar.close.show"
        >
          {{ snackbar.close.text }}
        </v-btn>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    message: null,
    options: {
      color: "primary",
      width: 290,
      zIndex: 200
    },
    snackbarVisible: false,
    snackbar: {
      enabled: false,
      icon: "mdi-check-circle",
      color: "white",
      mode: "multi-line",
      timeout: 3000,
      message: "Success!",
      callback: null,
      location: "bottom",
      x: "right",
      y: "top",
      close: {
        text: "Close",
        color: "dark",
        show: false
      }
    }
  }),
  methods: {
    /**
     * Show loader without any callbacks or timeout (must be manually hidden)
     * @param {string} message
     * @param {object} options
     */
    show(message, options) {
      this.dialog = true;
      this.message = message;
      this.options = Object.assign(this.options, options);
    },
    /**
     * Hide loader (and show snackbar if enabled)
     */
    hide() {
      this.dialog = false;
      if (this.snackbar.enabled) {
        this.showSnackbar();
      }
    },
    /**
     * Start/Show Loader (and maybe Snackbar)
     *
     * @param {string} message
     * @param {object} options
     * @param {Promise.<function>} [callback]
     * @param {boolean|object} [snackbar]
     */
    start(message, options, callback, snackbar) {
      this.show(message, options);

      if (typeof snackbar === "object") {
        this.snackbar.enabled = true;
        this.snackbar = Object.assign(this.snackbar, snackbar);
      } else if (typeof snackbar !== "undefined") {
        this.snackbar.enabled = true;
      }

      if (typeof callback === "function") {
        callback()
          .then(this.hide)
          .catch(error => {
            this.snackbar.color = "error";
            this.snackbar.message = error;
            this.hide();
          });
      }
    },
    /**
     * Stop/Hide loader and show snackbar with optional callback
     *
     * @param {string} message
     * @param {object} snackbarOptions
     * @param {function} callback
     */
    stop(message, snackbarOptions, callback) {
      this.hide();

      this.snackbar.enabled = true;
      this.snackbar = Object.assign(this.snackbar, snackbarOptions);

      if (typeof callback === "function") {
        this.snackbar.callback = callback;
        // Use our own timeout to call callback
        setTimeout(this.hideSnackbar, this.snackbar.timeout);
        // Set to zero to allow our timeout above to handle hiding
        this.snackbar.timeout = 0;
      }
    },
    /**
     * Hide Snackbar (and call callback if previously set)
     */
    hideSnackbar() {
      this.snackbarVisible = false;
      if (this.snackbar.callback) {
        this.snackbar.callback();
      }
    },
    /**
     * Show Snackbar
     * @param {string} message
     * @param {object} snackbarOptions
     */
    showSnackbar(message, snackbarOptions) {
      this.snackbar = Object.assign(this.snackbar, snackbarOptions);
      if (this.snackbar.color === "red") {
        this.snackbar.timeout = 5000;
        this.snackbar.icon = "mdi-close-circle";
      } else if (this.snackbar.color === "green") {
        this.snackbar.timeout = 3000;
        this.snackbar.icon = "mdi-check-circle";
      } else {
        this.snackbar.timeout = 3000;
        this.snackbar.icon = "mdi-information";
      }

      if (message) {
        this.snackbar.message = message;
      }
      this.snackbar.enabled = true;

      //   if (typeof snackbar === "object") {
      //     this.snackbar = Object.assign(this.snackbar, snackbarOptions);
      //   }

      this.snackbarVisible = true;
    }
  }
};
</script>
