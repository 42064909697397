const dkroutes = {
  name: "DK",
  path: "dk",
  component: () => import("./DK.vue"),
  children: [
    {
      path: "navigation-menu",
      name: "Navigation Menu DK",
      component: () =>
        import("@/view/pages/dk/navigationmenu/NavigationMenu.vue")
    }
  ]
};

export { dkroutes };
