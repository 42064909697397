const login_routes = {
  name: "login",
  path: "/login",
  component: () => import("@/view/pages/auth/login/Login.vue")
};

const logout_routes = {
  name: "logout",
  path: "/logout",
  component: () => import("@/view/pages/auth/logout/Logout.vue")
};

export { login_routes, logout_routes };
