import dayjs from "dayjs";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default function formatDate(input) {
  if (input) {
    return dayjs(input).format("DD-MM-YYYY HH:mm:ss");
  } else {
    return "";
  }
}
