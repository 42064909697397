import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import filters from "./core/filters";
import VueJWT from "vuejs-jwt";
import "./core/linq";

// Middleware
import AmplifyMiddleware from "./core/middleware/amplify";
import ApolloMiddleware from "./core/middleware/apollo";
// Vue 3rd party plugins
import i18n from "./core/plugins/vue-i18n";
import vuetify from "./core/plugins/vuetify";
import "./core/plugins/portal-vue";
import "./core/plugins/bootstrap-vue";
import "./core/plugins/perfect-scrollbar";
import "./core/plugins/inline-svg";
import "./core/plugins/metronic";
import VueProgressBar from "@/view/content/progress_bar/index";

// Global 3rd party plugins
import "bootstrap";
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
import rbac from "./core/plugins/rbac";

Vue.config.productionTip = false;
window.PerfectScrollbar = PerfectScrollbar;

AmplifyMiddleware.init();
ApolloMiddleware.init();

Vue.use(filters);
Vue.use(VueJWT);
Vue.use(VueProgressBar, {
  color: "#ffb423",
  thickness: "3px",
  position: "fixed"
});

Vue.use(rbac);

Vue.config.errorHandler = function (err) {
  // eslint-disable-next-line no-undef
  newrelic.noticeError(err);
};

const app = new Vue({
  router,
  store,
  i18n,
  vuetify,
  apolloProvider: ApolloMiddleware.appsyncProvider,
  render: h => h(App)
}).$mount("#app");

window.app = app;
export default { app };
