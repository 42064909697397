<template>
  <v-app>
    <router-view />
    <confirm ref="confirm"></confirm>
    <snackbar ref="snackbar"></snackbar>
  </v-app>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "~@mdi/font/css/materialdesignicons.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";
// Main demo style scss
@import "assets/sass/style.vue";
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/store/common/config.module";
import Confirm from "@/view/content/Confirm";
import Snackbar from "@/view/content/Snackbar";

export default {
  name: "OneSentia",
  components: {
    Confirm,
    Snackbar
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/common/config/layout.config.json)
     */
    this.$root.$confirm = this.$refs.confirm;
    this.$root.$snackbar = this.$refs.snackbar;
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  }
};
</script>
