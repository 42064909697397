import { AUTH_MODULE } from "@/core/config/role.config";

const beroutes = {
  name: "BE",
  path: "be",
  component: () => import("./BE.vue"),
  children: [
    {
      path: "navigation-menu",
      name: "Navigation Menu BE",
      component: () =>
        import("@/view/pages/be/navigationmenu/NavigationMenu.vue"),
      meta: {
        requiresAuth: true,
        roles: [
          ...AUTH_MODULE.BE_CRITICALS,
          ...AUTH_MODULE.BE_AVAILABILITY_REPORTS,
          ...AUTH_MODULE.BE_SERVICE_MANAGEMENT_REPORTS
        ]
      }
    },
    // DASHBOARDS
    {
      path: "dashboards/criticals",
      name: "Criticals",
      component: () =>
        import("@/view/pages/be/dashboards/criticals/Criticals.vue"),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.BE_CRITICALS
      }
    },
    // REPORTING
    {
      path: "reporting/availability-report",
      name: "Availability Report",
      component: () =>
        import(
          "@/view/pages/be/reporting/availability_report/AvailabilityReport.vue"
        ),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.BE_AVAILABILITY_REPORTS
      }
    },
    {
      path: "reporting/service-management-report",
      name: "Service Management Report",
      component: () =>
        import(
          "@/view/pages/be/reporting/service_management/ServiceManagement.vue"
        ),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.BE_SERVICE_MANAGEMENT_REPORTS
      }
    }
  ]
};

const criticalsscreenroutes = {
  name: "BE Dashboards Criticals",
  path: "/be/dashboards/screen/criticals",
  component: () => import("@/view/pages/be/dashboards/criticals/Criticals.vue")
};

export { beroutes, criticalsscreenroutes };
