import app from "@/main.js";

const DialogService = {
  loadingDialog: null,
  currentState: false,
  show() {
    this.currentState = true;
    app.app.$Progress.start(5000);
  },
  hide() {
    this.currentState = false;
    app.app.$Progress.finish();
  },
  isProgressBarVisible() {
    return this.currentState;
  },
  async confirm(app, title, message, options) {
    return await app.$root.$confirm.open(title, message, options ?? {});
  },
  close(app) {
    app.$root.$confirm.cancel();
  },
  updateBodyContent(app, title, message, closeButtonDisabled) {
    app.$root.$confirm.updateBodyContent(title, message, closeButtonDisabled);
  }
};

export default DialogService;
