// mutation types
export const APPEND_BREADCRUM = "appendBreadcrumb";

// action types
export const SET_BREADCRUMB = "setBreadcrumb";
export const ADD_BREADCRUMB = "addBreadcrumb";

export const SET_SUBHEADER_TOGGLE_DISPLAY = "setToggleButton";

export default {
  state: {
    breadcrumbs: [],
    subheaderToggleDisplay: false
  },
  getters: {
    subheaderToggleDisplay(state) {
      return state.subheaderToggleDisplay;
    },
    breadcrumbs(state) {
      return state.breadcrumbs;
    },
    pageTitle(state) {
      let last = state.breadcrumbs[state.breadcrumbs.length - 1];
      if (last && last.title) {
        return last.title;
      }
    }
  },
  actions: {
    [SET_SUBHEADER_TOGGLE_DISPLAY](state, payload) {
      state.commit(SET_SUBHEADER_TOGGLE_DISPLAY, payload);
    },
    [SET_BREADCRUMB](state, payload) {
      state.commit(SET_BREADCRUMB, payload);
    },

    [ADD_BREADCRUMB](state, payload) {
      if (typeof payload === "object") {
        payload.forEach(item => state.commit(APPEND_BREADCRUM, item));
      } else {
        state.commit(APPEND_BREADCRUM, payload);
      }
    }
  },
  mutations: {
    [APPEND_BREADCRUM](state, payload) {
      state.breadcrumbs = [...state.breadcrumbs, payload];
    },
    [SET_BREADCRUMB](state, payload) {
      state.breadcrumbs = payload;
    },
    [SET_SUBHEADER_TOGGLE_DISPLAY](state, payload) {
      state.subheaderToggleDisplay = payload;
    }
  }
};
